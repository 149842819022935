import React, { useMemo } from 'react';
import { Loader } from '../Loader';
import { IconChevronRight } from '@veneer/core';
import testid from '../../data-testid';
import * as S from './styles';
import * as T from './types';

export const StatusContentItem = ({
  title,
  value = 0,
  isLoading,
  onClick,
  loaderSize,
  chevronSize,
  circleRadius,
  circleColor,
  circleBorder,
  circleSize,
  titleGap,
  margin,
  height,
  padding,
  ...props
}: T.StatusContentItemPropsType) => {
  const titleProps = useMemo(() => {
    if (onClick) {
      return {
        onClick,
        ishover: true
      };
    }

    return {};
  }, [onClick]);

  const classText = onClick ? 'label' : 'caption';
  const classValue = onClick ? 'body-large' : 'body';

  return (
    <S.Container
      data-testid={testid(
        `status-content-item${props?.name?.toLowerCase?.() || ''}`
      )}
      {...{
        ...props,
        ...titleProps,
        height,
        margin,
        padding
      }}
    >
      <S.LeftContent
        className="label"
        {...{ titleGap }}
      >
        <S.OnlineStatus
          {...{
            size: circleSize,
            color: circleColor,
            radius: circleRadius,
            border: circleBorder
          }}
        />
        <S.ItemText
          className={classText}
          data-testid={testid(
            `${title.toLowerCase().split(' ').join('-')}-label`
          )}
        >
          {title}
        </S.ItemText>
      </S.LeftContent>
      <S.RightContent className="subtitle-regular">
        {isLoading ? (
          <Loader size={loaderSize} />
        ) : (
          <S.ItemText
            className={classValue}
            data-testid={testid(
              `${title.toLowerCase().split(' ').join('-')}-count`
            )}
          >
            {value}
          </S.ItemText>
        )}
        <S.RightArrow show={!!onClick}>
          <IconChevronRight size={chevronSize} />
        </S.RightArrow>
      </S.RightContent>
    </S.Container>
  );
};
export default StatusContentItem;
