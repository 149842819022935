import styled from 'styled-components';

type ContainerPropsType = {
  size?: string;
};

export const Container = styled.div<ContainerPropsType>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  * {
    ${({ size }) => {
      if (size) {
        return `
          min-height: none;
          height: ${size};
          max-height: 100%;
          min-width: none;
          width: ${size};
          max-width: 100%;
        `;
      }
    }}
  }
`;
