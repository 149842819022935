import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { Card } from '@veneer/core';

export const VeneerCard = styled(Card)<{ customMinHeight?: string }>`
  width: auto;
  min-height: ${({ customMinHeight }) => customMinHeight};
  font-weight: 400;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${primitives.layout.size4}px 0 ${primitives.layout.size4}px
    ${primitives.layout.size5}px;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.1);
`;

export const HeaderTitle = styled.span`
  font-style: normal;
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight3};
`;

export const CardContent = styled.div<{
  customHeight?: string;
  customPadding?: boolean;
}>`
  height: ${({ customHeight }) => customHeight || '252px'};

  @media only screen and (max-width: 992px) {
    height: ${({ customHeight }) => customHeight || '249px'};
  }

  padding: ${primitives.layout.size0}px;
`;
export const ListItem = styled.div<{
  customHeight?: string;
  onHover?: boolean;
  customPadding: string;
}>`
  height: ${({ customHeight }) => customHeight || '50px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ customPadding }) => customPadding};
  &:hover {
    background-color: ${({ onHover }) =>
      onHover ? `` : `${primitives.color.gray1}`};
    cursor: ${({ onHover }) => (onHover ? `` : `pointer`)};
  }
`;

export const ListItemTitle = styled.div`
  margin: 0 ${primitives.layout.size3}px;
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  display: flex;
  align-items: center;
  gap: ${primitives.layout.size2}px;
`;

export const ListItemCount = styled.div`
  gap: ${primitives.layout.size2}px;
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
  margin-right: ${primitives.layout.size3}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChevronContainer = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  margin-left: ${primitives.layout.size2}px;
`;

export const PrimaryDivider = styled.div`
  height: 3px;
  background: rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius2}px;
  margin: ${primitives.layout.size1}px 0;
`;

export const SecondaryDivider = styled.div<{ customMargin: string }>`
  height: 1px;
  background: rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius2}px;
  margin: ${({ customMargin }) => customMargin};
`;

export const IconOnline = styled.div`
  height: 12px;
  width: 12px;
  left: 0px;
  top: 4px;
  border-radius: 100%;
  background: ${primitives.color.green7};
`;

export const IconOffline = styled.div<{ customBackground: string }>`
  height: 12px;
  width: 12px;
  left: 0px;
  top: 4px;
  border-radius: 100%;
  background: ${({ customBackground }) => customBackground};
`;

export const LoaderContainer = styled.div`
  height: 100%;

  // flex
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  height: 52px;
  border-top: 1px solid rgba(33, 33, 33, 0.05);

  // flex
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const TotalDeviceCount = styled.span`
  color: ${primitives.color.gray6};
`;
