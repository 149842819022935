import primitives from '@veneer/primitives';
import { userInfo, deviceInfo } from './types';
import { Settings } from '../../contexts/Root/types';

export function getDeviceListConfig(
  settings: Settings,
  deviceInfo: deviceInfo
) {
  const createItem = (
    name: string,
    color: string,
    colorSettings: string,
    border?: string,
    borderSettings?: string
  ) => {
    return {
      name,
      circleBorder:
        settings?.[borderSettings] || primitives.color[border] || 'none',
      circleRadius: settings?.circleRadius || '50%',
      circleColor: settings?.[colorSettings] || primitives.color[color],
      circleSize: settings?.circleSize || `${primitives.layout.size2}px`,
      chevronSize: settings?.chevronSize || 12,
      titleGap: settings?.subTitleGap || `${primitives.layout.size3}px`,
      height: settings?.itemHeight || '',
      padding: settings?.itemPadding || `0 0 0 ${primitives.layout.size2}px`,
      margin: settings?.itemMargin || '0'
    };
  };
  return {
    title: deviceInfo.total,
    icon: true,
    chevronSize: settings?.chevronSize || 12,
    titlePadding:
      settings?.titlePadding || `0 0 0 ${primitives.layout.size2}px`,
    itemsMargin: settings?.itemsMargin || `0 0 0 ${primitives.layout.size2}px`,
    itemsPadding:
      settings?.itemsPadding || `0 0 0 ${primitives.layout.size2}px`,
    titleGap: settings?.titleGap || '0',
    mainItemHeight: settings?.mainItemHeight || '45px',
    containerHeight: settings?.listDevicesHeight || 'auto',
    items: [
      createItem(deviceInfo.online, 'hpBlue7', 'onlineColor'),
      createItem(
        deviceInfo.offline,
        'white',
        'offlineColor',
        'gray7',
        'offlineBorderColor'
      )
    ]
  };
}
export function getUserListConfig(
  settings: Settings,
  stateFilter: [string] | string[],
  userInfo: userInfo
) {
  const createItem = (name: string, color: string, colorSettings: string) => {
    return {
      name,
      circleRadius: settings?.circleRadius || '50%',
      circleColor: settings?.[colorSettings] || primitives.color[color],
      circleSize: settings?.circleSize || `${primitives.layout.size2}px`,
      chevronSize: settings?.chevronSize || 12,
      titleGap: settings?.subTitleGap || `${primitives.layout.size3}px`,
      padding: settings?.itemPadding || `0 0 0 ${primitives.layout.size2}px`,
      margin: settings?.itemMargin || '0',
      height: settings?.itemHeight || ''
    };
  };

  const items = [
    createItem(userInfo.active, 'green5', 'activeColor'),
    createItem(userInfo.pending, 'gray5', 'pendingColor')
  ];

  if (stateFilter.includes('Expired')) {
    items.push(createItem(userInfo.expired, 'red3', 'expiredColor'));
  }

  return {
    title: userInfo.total,
    icon: true,
    chevronSize: settings?.chevronSize || 12,
    titlePadding:
      settings?.titlePadding || `0 0 0 ${primitives.layout.size2}px`,
    itemsMargin: settings?.itemsMargin || `0 0 0 ${primitives.layout.size2}px`,
    itemsPadding:
      settings?.itemsPadding || `0 0 0 ${primitives.layout.size2}px`,
    titleGap: settings?.titleGap || '0',
    mainItemHeight: settings?.mainItemHeight || '45px',
    containerHeight: settings?.listUsersHeight || 'auto',
    items
  };
}
