export type Device = {
  deviceId: string;
  lastUpdatedAt: string;
  type: string;
  connTypes: [string];
  identity: {
    lastUpdatedAt: string;
    serialNumber: string;
    deviceUuid: string;
    makeAndModel: {
      name: string;
      number: string;
      series: string;
    };
    firmwareVersion: string;
    friendlyName: string;
    location: string;
    supplyType: string;
    supplyDelivery: string;
    colorSupported: boolean;
    bizModel: string;
    benefitsControlModel: string;
    platformIdentifier: string;
    displayProfile: string;
    bleHPSpecVersion: string;
    deviceSegment: string;
    programLevel: string;
    countryRegion: string;
    language: string;
  };
  images: [
    {
      url: string;
    }
  ];
  software: [unknown];
  hardware: [unknown];
  status: {
    lastUpdatedAt: string;
    connectionStateLastUpdatedAt: string;
    connectionState: string;
    connectionStateCode: number;
    powerState: string;
    scanADFState: string;
    scanState: string;
    printerState: string;
    printerStateSeverity: string;
    printerStateReasons: [string];
    acceptingJobs: boolean;
  };
  ownership: {
    lastUpdatedAt: string;
    accountId: string;
  };
  timestamp: {
    lastUpdatedAt: string;
    lastSeenTime: string;
    lastRegistrationTime: string;
    firstRegistrationTime: string;
    firmwareDate: string;
    claimTime: string;
    claimRevokeTime: string;
    deviceTime: string;
  };
  network: {
    lastUpdatedAt: string;
    adapters: [
      {
        enabled: boolean;
        type: string;
        name: string;
        macAddress: string;
        hostname: string;
        ipv4: {
          enabled: boolean;
          address: {
            ip: string;
          };
        };
        ipv6: {
          enabled: boolean;
          address: {
            ip: string;
          };
        };
      }
    ];
  };
  supplies: {
    lastUpdatedAt: string;
    overallLevelStateCode: string;
    consumables: [
      {
        slotIdentifier: number;
        slotOrder: string;
        slot: number;
        serialNumber: string;
        supplyType: string;
        isRefilled: boolean;
        isVaReman: boolean;
        isTrial: boolean;
        isGenuineHP: boolean;
        productNumber: string;
        colorCode: string;
        colors: [string];
        percentLifeRemaining: string;
        percentLifeDisplay: number;
        levelState: string;
        levelStateCode: string;
        state: string;
        stateReasons: [string];
        brand: string;
        manufactureDate: string;
        consumablePlatform: string;
        supplyModelType: string;
      }
    ];
  };
  mediaTypes: {
    lastUpdatedAt: string;
    defaultMediaSource: string;
    inputs: [
      {
        type: string;
        state: string;
        stateReason: string;
        mediaSourceId: string;
      }
    ];
    outputs: [
      {
        type: string;
        operationMode: string;
        state: string;
        stateReason: string;
      }
    ];
    paths: [
      {
        mediaPathId: string;
        mediaPathType: string;
        state: string;
        stateReason: string;
      }
    ];
  };
  solutions: [string];
  solutionMetadata: {
    solution_client_id: {
      a_key_1: string;
      a_key_2: string;
    };
  };
  tags: [string];
  solutionConfig: {
    cloudShortcutsEnabled: boolean;
    cloudShortcutsAvailable: boolean;
    eprintSupported: boolean;
    eprintEmailAddress: string;
    printOnTheGoSupport: string;
  };
  outOfSync: boolean;
  mockStatus?: string;
};

export enum DeviceStatus {
  READY = 'ready',
  WARNING = 'warning',
  ERROR = 'error'
}
