import styled from 'styled-components';
import { Card as VeneerCard } from '@veneer/core';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  & > *:first-child {
    box-sizing: border-box;
    padding: 0;

    & > *:first-child {
      box-sizing: border-box;
      height: 56px;
    }
  }
`;

export const Content = styled.div<{ customStyle?: string }>`
  margin-top: -${primitives.layout.size5}px;
  height: 377px;
  max-height: 377px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${primitives.layout.size1}px;
  width: 100%;

  // Hide scrollbar on IE and Edge
  -ms-overflow-style: none;
  // Hide scrollbar on Firefox
  scrollbar-width: none;
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  & > *:not(:last-child) {
    padding-bottom: ${primitives.layout.size1}px;
    border-bottom: solid 3px rgba(33, 33, 33, 0.1);
  }

  ${({ customStyle }) => customStyle};
`;

export const Card = styled(VeneerCard)<{
  borderRadius?: string;
  bodyStyle?: string;
}>`
  border-radius: ${({ borderRadius }) => borderRadius};

  > div {
    ${({ bodyStyle }) => bodyStyle}
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.stroke.default}};
`;

export const Title = styled.h1`
  font-size: ${primitives.layout.size5}px;
  line-height: 1.2;
  margin-right: auto;
`;

export const CardBody = styled.div`
  padding: 0 ${primitives.layout.size5}px ${primitives.layout.size3}px;
`;
