import React from 'react';
import { ProgressIndicator } from '@veneer/core';
import * as S from './styles';
import * as T from './types';
import testid from '../../data-testid';

export const LoaderWidget = (props: T.LoaderWidgetPropsType) => (
  <S.Container
    {...props}
    data-testid={testid('loader-widget-component')}
  >
    <ProgressIndicator />
  </S.Container>
);
export default LoaderWidget;
