import styled from 'styled-components';
import { StatusContentItem } from '../StatusContentItem';

export const Container = styled.div<{ height?: string }>`
  height: ${({ height }) => height};
  &,
  * {
    box-sizing: border-box;
  }
`;

export const MainItem = styled(StatusContentItem)<{
  height?: string;
}>`
  height: ${({ height }) => height} !important;
  && {
    border-width: 0;
  }
`;

export const ContentList = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding};
`;

export const SubItem = styled(StatusContentItem)`
  border-top: 1px solid #f0f0f0;
  height: 45px;
`;

export const ContainerError = styled.div`
  display: inline;
  align-items: center;
  justify-content: center;
`;
