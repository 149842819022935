import { Stack } from '@jarvis/web-stratus-client';
import { JarvisAuthProvider } from '@jarvis/web-http';
import getCachedListDevicesCount from '../stratus/getCachedListDevicesCount';
import * as T from '../../types/statusContentType';

export default async function getCachedDevicesStatusCount(
  stack: Stack,
  authProvider: JarvisAuthProvider
): Promise<T.StatusContentType> {
  const getData = async (isOnline: boolean) =>
    getCachedListDevicesCount(stack, authProvider, {
      params: { connectionState: isOnline ? 'online' : 'offline' }
    }) || 0;

  const online = await getData(true);
  const offline = await getData(false);

  return {
    type: T.StatusType.Device,
    totalCount: online + offline,
    statusList: [
      {
        status: T.DeviceStatusType.Online,
        value: online
      },
      {
        status: T.DeviceStatusType.Offline,
        value: offline
      }
    ]
  };
}
