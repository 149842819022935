import * as T from '../../types/statusContentType';

export const activeUsersList: T.UserStatusItemType = {
  status: T.UserStatusType.Active,
  value: 0
};

export const pendingUsersList: T.UserStatusItemType = {
  status: T.UserStatusType.Pending,
  value: 0
};

export const expiredUsersList: T.UserStatusItemType = {
  status: T.UserStatusType.Expired,
  value: 0
};
export type stateFilter = {
  stateFilter: [string] | string[];
};
export function getUsersList(
  stateFilter: [string] | string[]
): T.StatusContentType {
  const statusList = [];

  if (stateFilter.includes('Active')) {
    statusList.push(activeUsersList);
  }

  if (stateFilter.includes('Pending')) {
    statusList.push(pendingUsersList);
  }

  if (stateFilter.includes('Expired')) {
    statusList.push(expiredUsersList);
  }

  return {
    type: T.StatusType.User,
    totalCount: 0,
    statusList
  };
}
