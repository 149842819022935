import React, { useState, useEffect, useCallback } from 'react';
import primitives from '@veneer/primitives';
import { ErrorComponent } from '../ErrorComponent';
import testid from '../../data-testid';
import * as S from './styles';
import * as T from './types';
import { LoaderWidget } from '../../shared/LoaderWidget';
import { useRootContext } from '../../contexts/Root';
import { publishEvent } from '../../utils/analytics';

export const StatusListContent = ({
  statusRequest,
  defaultValue,
  loaderSize = primitives.typography.size4,
  listConfig,
  singleLoader,
  redirectPath,
  analyticsEvent: eventAnalytics,
  ...props
}: T.StatusListContentPropsType) => {
  const [data, setData] = useState<T.StatusListDataType>(defaultValue);
  const [state, setState] = useState<T.StatusListStateType>('loading');
  const isError = state === 'error';
  const { shell } = useRootContext();
  const { navigation } = shell;

  const updateStates = useCallback(() => {
    setData(defaultValue);
    setState('loading');

    statusRequest()
      .then((newData) => {
        setData({ ...defaultValue, ...newData });
        setState('success');
      })
      .catch(() => {
        setData(defaultValue);
        setState('error');
      });
  }, [defaultValue, statusRequest]);

  useEffect(() => {
    updateStates();
  }, [updateStates]);

  const subItems = data.statusList?.map?.(({ value }, i) => {
    const itemConfig = listConfig.items[i];

    return (
      <S.SubItem
        key={itemConfig.name}
        isLoading={state === 'loading' && !singleLoader}
        value={value}
        title={itemConfig.name}
        loaderSize={loaderSize}
        height={itemConfig.height}
        {...itemConfig}
      />
    );
  });

  return (
    <S.Container
      data-testid={testid('status-list-content')}
      {...props}
      height={listConfig.containerHeight}
    >
      {isError && (
        <S.ContainerError>
          <ErrorComponent onClick={updateStates} />
        </S.ContainerError>
      )}
      {!isError && state === 'loading' && singleLoader && (
        <LoaderWidget fullScreen />
      )}
      {!isError && (!singleLoader || (state != 'loading' && singleLoader)) && (
        <>
          <S.MainItem
            data-testid={testid(
              `${listConfig.title.toLowerCase().split(' ').join('-')}-button`
            )}
            title={listConfig.title}
            value={data.totalCount}
            loaderSize={loaderSize}
            isLoading={state === 'loading' && !singleLoader}
            onClick={() => {
              publishEvent(eventAnalytics(data.totalCount));
              navigation?.push?.(redirectPath);
            }}
            chevronSize={listConfig.chevronSize}
            padding={listConfig.titlePadding}
            titleGap={listConfig.titleGap}
            height={listConfig.mainItemHeight}
          />
          <S.ContentList padding={listConfig.itemsPadding}>
            {subItems}
          </S.ContentList>
        </>
      )}
    </S.Container>
  );
};
export default StatusListContent;
