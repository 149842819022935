import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import * as T from './types';
import testid from '../../data-testid';
import {
  Button,
  IconCheckmarkCircle,
  IconChevronRight,
  IconWarningAlt,
  IconXCircle,
  ProgressIndicator
} from '@veneer/core';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { Stack } from '@jarvis/web-stratus-client';
import { isServiceCustomer } from '../../../src/utils/api';
import { useRootContext } from '../../contexts/Root';
import { constructUrlWithParams, handleOnClick } from './commonMethods';
import { OnlineStatus } from './constants';
import { Device, DeviceStatus } from '../../types/deviceTypes';
import ThemeProvider from '@veneer/theme/dist/theme_provider';
import primitives from '@veneer/primitives/dist/primitives';

export const EcpCard = ({
  stack,
  enablePrinterStrings,
  enableSSOLink,
  mockDevicesData,
  ...props
}: T.EcpCardPropTypes) => {
  const { useOrgedAuthProvider, shell } = props;
  const { authProvider } = shell;
  const { localization, navigation } = useRootContext();
  const { t } = localization.useReactTranslatorHook();
  const [isLoading, setIsLoading] = useState<boolean>(
    props.mockProps ? false : true
  );
  const [totalDeviceCount, setTotalDeviceCount] = useState<number>(0);
  const [onlineDeviceCount, setOnlineDeviceCount] = useState<number>(0);
  const [offlineDeviceCount, setoOfflineDeviceCount] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isServiceProvider, setIsServiceProvider] = useState<boolean>(
    props.mockServiceProvider ? true : false
  );

  // WFC demo
  const [mockedDevicesCount, setMockedDevicesCount] = useState(0);
  const [mockedOnlineDevicesCount, setMockedOnlineDevicesCount] = useState(0);
  const [mockedOfflineDevicesCount, setMockedOfflineDevicesCount] = useState(0);
  const [readyDevicesCount, setReadyDevicesCount] = useState(0);
  const [warningDevicesCount, setWarningDevicesCount] = useState(0);
  const [errorDevicesCount, setErrorDevicesCount] = useState(0);

  const baseAuthProvider = useOrgedAuthProvider
    ? authProvider?.createOrgedAuthProvider?.()
    : authProvider;

  const onClick = () => {
    return handleOnClick(navigation, enableSSOLink);
  };

  const onChevronClick = (connectivity?: string, status?: string) => {
    if (!isServiceProvider) {
      return constructUrlWithParams(navigation, connectivity, status);
    }
  };

  const getDevices = useCallback(async () => {
    const urlEnv = (() => {
      switch (stack) {
        case Stack.prod:
          return '';
        case Stack.stage:
          return 'stage-';
        default:
          return 'pie-';
      }
    })();
    const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/devicecache/v1/devices`;
    const httpClient = new JarvisWebHttpClient({
      defaultAuthProvider: baseAuthProvider,
      defaultBaseURL: baseUrl
    });
    const responseTotal = await httpClient.head({
      params: {}
    });
    const xTotalCount = 'x-total-count';
    setTotalDeviceCount(Number(responseTotal?.headers?.[xTotalCount]) || 0);
    const responseOnline = await httpClient.head({
      params: {
        connectionState: 'online'
      }
    });
    setOnlineDeviceCount(Number(responseOnline?.headers?.[xTotalCount] || 0));
    setoOfflineDeviceCount(
      Number(responseTotal?.headers?.[xTotalCount]) -
        Number(responseOnline?.headers?.[xTotalCount]) || 0
    );

    const devicesOnline = await httpClient.get({
      params: {
        connectionState: 'online',
        offset: 0,
        limit: onlineDeviceCount
      }
    });
    const devicesData: Device[] = mockDevicesData
      ? mockDevicesData
      : devicesOnline.data;
    const assignStatus = (): Device[] => {
      return devicesData?.map((item) => {
        const randomFloat = function () {
          const int = window.crypto.getRandomValues(new Uint32Array(1))[0];
          return int / 2 ** 32;
        };
        let mockStatus: DeviceStatus;
        if (randomFloat() < 0.8) {
          mockStatus = DeviceStatus.READY;
        } else if (randomFloat() < 0.9) {
          mockStatus = DeviceStatus.WARNING;
        } else {
          mockStatus = DeviceStatus.ERROR;
        }
        return { ...item, mockStatus };
      });
    };
    const mockedOnlineDevices = assignStatus();
    setMockedDevicesCount(Number(responseTotal?.headers?.[xTotalCount]) || 0);
    setMockedOnlineDevicesCount(
      Number(responseOnline?.headers?.[xTotalCount] || 0)
    );
    setMockedOfflineDevicesCount(
      Number(responseTotal?.headers?.[xTotalCount]) -
        Number(responseOnline?.headers?.[xTotalCount]) || 0
    );
    setReadyDevicesCount(
      mockedOnlineDevices?.filter(
        (item) => item?.mockStatus === DeviceStatus.READY
      ).length
    );
    setWarningDevicesCount(
      mockedOnlineDevices?.filter(
        (item) => item?.mockStatus === DeviceStatus.WARNING
      ).length
    );
    setErrorDevicesCount(
      mockedOnlineDevices?.filter(
        (item) => item?.mockStatus === DeviceStatus.ERROR
      ).length
    );

    const serviceCustomer = await isServiceCustomer(props);
    setIsDisabled(
      (useOrgedAuthProvider && !serviceCustomer) ||
        Number(responseTotal.headers?.[xTotalCount]) === 0
    );
    setIsServiceProvider(useOrgedAuthProvider && !serviceCustomer);
    setIsLoading(false);
    return null;
  }, [
    baseAuthProvider,
    mockDevicesData,
    onlineDeviceCount,
    props,
    stack,
    useOrgedAuthProvider
  ]);

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceProviderChevron = () => (
    <IconChevronRight
      color="gray9"
      size={16}
    />
  );
  const getStatusIcon = (label) => {
    if (label === 'ready') {
      return (
        <IconCheckmarkCircle
          data-testid={testid('card-icon-ready')}
          filled
          color="green6"
        />
      );
    } else if (label === 'warning') {
      return (
        <IconWarningAlt
          data-testid={testid('card-icon-warning')}
          filled
          color="darkOrange6"
        />
      );
    } else {
      return (
        <IconXCircle
          data-testid={testid('card-icon-error')}
          filled
          color="red6"
        />
      );
    }
  };

  const getStatusCount = (label) => {
    if (label === 'ready') {
      return readyDevicesCount;
    } else if (label === 'warning') {
      return warningDevicesCount;
    } else {
      return errorDevicesCount;
    }
  };

  const showFooter = isServiceProvider || enablePrinterStrings;

  const getContent = (
    <>
      {isLoading ? (
        <S.LoaderContainer>
          <ProgressIndicator />
        </S.LoaderContainer>
      ) : (
        <>
          <S.CardHeader data-testid={testid('card-header')}>
            <S.HeaderTitle data-testid={testid('card-header-title')}>
              {enablePrinterStrings
                ? `${enablePrinterStrings}`
                : `${t('status.header.devices.devices', 'Devices')}`}
              {!enablePrinterStrings ? (
                <S.TotalDeviceCount> ({totalDeviceCount})</S.TotalDeviceCount>
              ) : (
                ''
              )}
            </S.HeaderTitle>
          </S.CardHeader>
          <S.CardContent
            data-testid={testid('card-content')}
            customHeight={enablePrinterStrings ? 'auto' : '282px'}
            customPadding={true}
          >
            <>
              <S.ListItem
                onHover={isServiceProvider}
                customPadding={`${primitives.layout.size0}px ${primitives.layout.size5}px`}
                data-testid={testid('card-listitem-1')}
                onClick={() => onChevronClick('online')}
              >
                <S.ListItemTitle data-testid={testid('card-listitem-title-1')}>
                  <S.IconOnline data-testid={testid('card-icon-online')} />
                  {t('status.header.devices.online', 'Online')}
                </S.ListItemTitle>
                <S.ListItemCount data-testid={testid('card-listitem-count-1')}>
                  {enablePrinterStrings
                    ? `${mockedOnlineDevicesCount} / ${mockedDevicesCount}`
                    : `${onlineDeviceCount} / ${totalDeviceCount}`}
                  {!isServiceProvider ? getServiceProviderChevron() : ''}
                </S.ListItemCount>
              </S.ListItem>
              <S.SecondaryDivider
                customMargin={`${primitives.layout.size0}px ${primitives.layout.size5}px ${primitives.layout.size0}px ${primitives.layout.size5}px`}
                data-testid={testid('card-secondary-divider')}
              />
              <div>
                {OnlineStatus.map((statusLabel) => (
                  <>
                    <S.ListItem
                      onHover={isServiceProvider}
                      data-testid={testid(`card-listitem-${statusLabel.value}`)}
                      customHeight={enablePrinterStrings ? '34px' : '50px'}
                      customPadding={`${primitives.layout.size0}px ${primitives.layout.size5}px ${primitives.layout.size0}px 44px`}
                      onClick={() =>
                        onChevronClick('online', statusLabel.value)
                      }
                    >
                      <S.ListItemTitle
                        data-testid={testid(
                          `card-listitem-title-${statusLabel.value}`
                        )}
                      >
                        {getStatusIcon(statusLabel.value)}
                        {statusLabel.label}
                      </S.ListItemTitle>
                      <S.ListItemCount
                        data-testid={testid(
                          `card-listitem-count-${statusLabel.value}`
                        )}
                      >
                        {getStatusCount(statusLabel.value)}
                        {!isServiceProvider ? getServiceProviderChevron() : ''}
                      </S.ListItemCount>
                    </S.ListItem>
                    {statusLabel.value !== 'error' && (
                      <S.SecondaryDivider
                        customMargin={`${primitives.layout.size0}px ${primitives.layout.size5}px ${primitives.layout.size0}px 44px`}
                        data-testid={testid('card-secondary-divider')}
                      />
                    )}
                  </>
                ))}
              </div>

              <S.PrimaryDivider
                data-testid={testid('card-primary-divider-1')}
              />

              <S.ListItem
                onHover={isServiceProvider}
                customPadding={`${primitives.layout.size0}px ${primitives.layout.size5}px`}
                data-testid={testid('card-listitem-5')}
                onClick={() => onChevronClick('offline')}
              >
                <S.ListItemTitle data-testid={testid('card-listitem-title-5')}>
                  <S.IconOffline
                    customBackground={
                      enablePrinterStrings
                        ? `${primitives.color.red7}`
                        : `${primitives.color.gray4}`
                    }
                    data-testid={testid('card-icon-offline')}
                  />
                  {t('status.header.devices.offline', 'Offline')}
                </S.ListItemTitle>
                <S.ListItemCount data-testid={testid('card-listitem-count-5')}>
                  {enablePrinterStrings
                    ? `${mockedOfflineDevicesCount} / ${mockedDevicesCount}`
                    : `${offlineDeviceCount} / ${totalDeviceCount}`}
                  {!isServiceProvider ? getServiceProviderChevron() : ''}
                </S.ListItemCount>
              </S.ListItem>
            </>
          </S.CardContent>
          {showFooter && (
            <S.Footer>
              <Button
                appearance="ghost"
                data-testid={testid('card-footer-button')}
                disabled={isDisabled}
                onClick={onClick}
                small
                trailingIcon={<IconChevronRight />}
              >
                {t('status.viewdetails', 'View Details')}
              </Button>
            </S.Footer>
          )}
        </>
      )}
    </>
  );

  return (
    <ThemeProvider density="high">
      <S.VeneerCard
        {...(enablePrinterStrings && { appearance: 'dropShadow' })}
        content={getContent}
        data-testid={testid('ecp-card')}
        customMinHeight={enablePrinterStrings ? 'auto' : '360px'}
      />
    </ThemeProvider>
  );
};

export default EcpCard;
