import React, { useCallback, useEffect, useMemo } from 'react';
import primitives from '@veneer/primitives';
import { StatusListContent } from '../StatusListContent';
import getUsersStatusCount from '../../services/getUsersStatusCount';
import getCachedDevicesStatusCount from '../../services/getCachedDevicesStatusCount';
import { getUsersList } from '../../services/getUsersStatusCount/defaultValues';
import { devicesList } from '../../services/getCachedDevicesStatusCount/defaultValue';
import { getDeviceListConfig, getUserListConfig } from './lists';
import testId from '../../data-testid';
import * as T from './types';
import * as S from './styles';
import { useRootContext } from '../../contexts/Root';
import {
  StatusTotalPrintersClicked,
  StatusTotalUsersClicked,
  publishEvent
} from '../../utils/analytics';

export const Card = ({
  analyticsEvents,
  className,
  stack,
  stateFilter = ['Active', 'Pending', 'Expired'],
  roleFilter = ['Admin-BusinessMPSCompany', 'Invitation'],
  ...props
}: T.CardPropsType) => {
  const { settings, customTexts, shell, localization } = useRootContext();
  const { authProvider } = shell;
  const { t } = localization.useReactTranslatorHook();

  const { moduleDisplayed } = analyticsEvents || {};

  useEffect(() => {
    if (moduleDisplayed) {
      publishEvent(moduleDisplayed);
    }
  }, [moduleDisplayed]);

  // FIXME: This property should not be used, and need to me removed in the future
  // as it will not work with the translation files
  const customT: typeof t = useCallback(
    (id, options) => {
      const customId = customTexts?.[id];
      if (!customId) {
        return t(id, options);
      }

      return t(customId, options);
    },
    [customTexts, t]
  );

  const userInfo = useMemo(
    () => ({
      total: customT('status.header.users.total', 'Total Users'),
      active: customT('status.header.users.active', 'Active'),
      pending: customT('status.header.users.pending', 'Pending'),
      expired: customT('status.header.users.expired', 'Expired')
    }),
    [customT]
  );

  const deviceInfo = useMemo(
    () => ({
      total: customT('status.header.devices.total', 'Total Devices'),
      online: customT('status.header.devices.online', 'Online'),
      offline: customT('status.header.devices.offline', 'Offline')
    }),
    [customT]
  );
  const fetchDevices = useCallback(async () => {
    return getCachedDevicesStatusCount(stack, authProvider);
  }, [stack, authProvider]);

  const fetchUsers = useCallback(async () => {
    return getUsersStatusCount(stack, authProvider, stateFilter, roleFilter);
  }, [stack, authProvider, stateFilter, roleFilter]);

  const deviceListConfig = useMemo(
    () => getDeviceListConfig(settings, deviceInfo),
    [settings, deviceInfo]
  );
  const userListConfig = useMemo(
    () => getUserListConfig(settings, stateFilter, userInfo),
    [settings, stateFilter, userInfo]
  );

  const defaultUserList = useMemo(
    () => getUsersList(stateFilter),
    [stateFilter]
  );

  return (
    <S.Card
      className={className}
      appearance="dropShadow"
      data-testid={testId('widget-card')}
      borderRadius={
        settings?.cardBorderRadius || `${primitives.layout.cornerRadius4}px`
      }
      bodyStyle={settings?.bodyStyle || ''}
      content={
        <>
          <S.Header>
            <S.Title>
              <div data-testid={testId('widget-card-title')}>
                {customT('status.header.title', 'Status')}
              </div>
            </S.Title>
          </S.Header>
          <S.CardBody>
            <S.Content
              data-testid={testId('status-list')}
              customStyle={settings?.contentStyle || ''}
            >
              <StatusListContent
                className="stratus-list-content-devices"
                statusRequest={fetchDevices}
                defaultValue={devicesList}
                listConfig={deviceListConfig}
                singleLoader={settings?.singleLoader || false}
                redirectPath={settings?.devicesRedirectPath || '/devices'}
                analyticsEvent={StatusTotalPrintersClicked}
                {...props}
              />
              {settings?.showUsers !== false && (
                <StatusListContent
                  className="stratus-list-content-users"
                  statusRequest={fetchUsers}
                  defaultValue={defaultUserList}
                  listConfig={userListConfig}
                  singleLoader={settings?.singleLoader || false}
                  redirectPath={settings?.usersRedirectPath || '/users'}
                  analyticsEvent={StatusTotalUsersClicked}
                  {...props}
                />
              )}
            </S.Content>
          </S.CardBody>
        </>
      }
    />
  );
};

export default Card;
