import { Stack, DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { JarvisAuthProvider } from '@jarvis/web-http';

export default async function getCachedListDevicesCount(
  stack: Stack,
  authProvider: JarvisAuthProvider,
  options: {
    params: {
      connectionState: 'online' | 'offline';
    };
  }
) {
  const deviceClient = new DeviceCacheApiClient(stack, authProvider);

  const response = await deviceClient.count({
    url: '/devices',
    ...options
  });

  const count = parseInt(response?.headers?.['x-total-count']);

  return count > 0 ? count : 0;
}
