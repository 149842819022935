import styled from 'styled-components';
import primitives from '@veneer/primitives';

type LeftContentType = {
  ishover?: boolean;
  height?: string;
  margin?: string;
  padding?: string;
};

export const Container = styled.div<LeftContentType>`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${({ height }) => height};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};

    color: ${primitives.color.gray12};
    border-radius: 0;

    :hover:not(:active) {
      background: none;
    }

    ${(props) =>
      props.ishover &&
      `
        :hover {
          cursor: pointer;
          color: ${primitives.color.hpBlue7};
      }`}

    > span {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const LeftContent = styled.div<{ titleGap?: string }>`
  && {
    max-width: 60%;
    display: flex;
    align-items: center;
    gap: ${({ titleGap }) => titleGap};
  }
`;

export const RightContent = styled.div`
  && {
    max-width: 40%;
    display: flex;
    align-items: center;
    gap: ${primitives.layout.size3}px;
  }
`;

export const ItemText = styled.p``;

type OnlineStatusType = {
  radius: string;
  color: string;
  size: string;
  border: string;
};

export const OnlineStatus = styled.div<OnlineStatusType>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  min-width: ${({ size }) => size};
  background: ${({ color }) => color};
  border-radius: ${({ radius }) => radius};
  border: ${({ border }) => `1px solid ${border}`};
`;

export const RightArrow = styled.div<{ show?: boolean }>`
  display: flex;
  align-items: center;
  justify-items: center;
  color: ${primitives.color.gray4};

  ${({ show }) => !show && 'visibility: hidden;'}
`;
