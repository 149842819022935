import React from 'react';
import { ProgressIndicator } from '@veneer/core';
import testid from '../../data-testid';
import * as S from './styles';
import * as T from './types';

export const Loader = (props: T.LoaderPropsType) => (
  <S.Container {...props}>
    <ProgressIndicator data-testid={testid('loader')} />
  </S.Container>
);
export default Loader;
