import React, { useMemo } from 'react';
import RootContext, { RootContext as RootContextType } from './RootContext';
import resources from '../../assets/locale';
import {
  shouldMockLocalization,
  getMockedLocalization
} from '../../mocks/mockedShell';
import { PreferencesContextProps } from '../Preferences/PreferencesContext';
import PreferencesProvider from '../Preferences/PreferencesProvider';

type RootProviderProps = {
  preferences: PreferencesContextProps;
};

const RootProvider = ({
  preferences,
  children,
  ...props
}: RootContextType & RootProviderProps) => {
  const { shell, settings } = props;
  const { localization: shellLocalization } = shell;

  const localization = shouldMockLocalization(
    !settings?.enableLocalization,
    shellLocalization
  )
    ? getMockedLocalization(resources, shellLocalization)
    : shellLocalization;

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  );

  const providerValue = {
    ...props,
    localization
  };

  return (
    <RootContext.Provider value={providerValue}>
      <PreferencesProvider value={preferences}>
        <TranslatorProvider resources={resources}>
          {children}
        </TranslatorProvider>
      </PreferencesProvider>
    </RootContext.Provider>
  );
};

export default RootProvider;
