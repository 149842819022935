import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: ${primitives.layout.size2}px;
  padding: ${primitives.layout.size4}px;
`;

export const IconDiv = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${primitives.color.gray2};
`;
