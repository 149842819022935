import styled from 'styled-components';
import * as T from './types';

export const Container = styled.div<T.LoaderWidgetPropsType>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ fullScreen }) =>
    fullScreen
      ? `height: 100%;
       width: 100%;`
      : `flex: 1;
  `}
`;

export default Container;
