import React from 'react';
import PreferencesContext, {
  PreferencesContextProps,
  defaultPreferences
} from './PreferencesContext';

type PreferencesProviderProps = {
  children: React.ReactNode;
  value: PreferencesContextProps;
};

function merge(object, defaultObject) {
  const mergedObject = { ...defaultObject, ...object };

  for (const [key, value] of Object.entries(mergedObject)) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      mergedObject[key] = merge(value, defaultObject[key]);
    }
  }

  return mergedObject;
}

const PreferencesProvider = ({ children, value }: PreferencesProviderProps) => {
  const mergedPreferences = merge(value, defaultPreferences);
  return (
    <PreferencesContext.Provider value={mergedPreferences}>
      {children}
    </PreferencesContext.Provider>
  );
};

export default PreferencesProvider;
