import React from 'react';
import { Card } from './components/Card';
import { EcpCard } from './components/EcpCard';
import { useRootContext } from './contexts/Root';
import usePreferencesContext from './contexts/Preferences/usePreferencesContext';

const StatusWidget = (props) => {
  const { stack, authProvider, navigation, ...rest } = useRootContext();
  const { manageStatusWidget } = usePreferencesContext();
  const { hideUsersCount } = manageStatusWidget || {};

  return !hideUsersCount ? (
    <Card
      authProvider={authProvider}
      stack={stack}
      navigation={navigation}
      {...props}
      {...rest}
    />
  ) : (
    <EcpCard
      authProvider={authProvider}
      stack={stack}
      navigation={navigation}
      enablePrinterStrings={props.enablePrinterStrings}
      enableSSOLink={props.enableSSOLink}
      mockDevicesData={props.mockDevicesData}
      {...props}
      {...rest}
    />
  );
};

export default StatusWidget;
