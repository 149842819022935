export enum StatusType {
  Device,
  User
}

export enum DeviceStatusType {
  Online,
  Offline
}

export enum UserStatusType {
  Active,
  Pending,
  Expired
}

export type UserStatusItemType = {
  status: UserStatusType;
  value: number;
};

export type DeviceStatusItemType = {
  status: DeviceStatusType;
  value: number;
};

export type StatusContentType = {
  type: StatusType;
  totalCount: number;
  statusList: (DeviceStatusItemType | UserStatusItemType)[];
};

export type criteriaType = {
  operation: string;
  propertyName: string;
  propertyValue: string | string[];
};

export type statusContentStatusListType = {
  id: number;
  value: number;
  title: string;
  icon: boolean;
  status?: string;
};

export type statusContentType = {
  name: string;
  value: number;
  detailedDevice: string;
  href: string;
  statusList: statusContentStatusListType[];
};
