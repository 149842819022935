export const isServiceCustomer = async (props) => {
  const criterion = await props.criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  });
  return criterion;
};
