import { Stack } from '@jarvis/web-stratus-client';
import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http';
import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client';
import * as T from './types';
import { AxiosResponse } from 'axios';

class PermissionSetSvcClientExt {
  // This is going to look better when moved to web-stratus-client
  // Should extend from StratusClient, but it is not exposed by web-stratus-client
  jarvisWebHttpInstance: JarvisWebHttpClient;
  apiName = 'ws-hp.com/perm-set-service';
  apiVersion: '1';
  permissionSetEndpoint = '/permission/set';

  static getUrl(stack: Stack) {
    const urlEnv = (() => {
      switch (stack) {
        case Stack.prod:
          return '';
        case Stack.stage:
          return 'stage-';
        case Stack.pie:
          return 'pie-';
        default:
          return 'dev-';
      }
    })();

    return `https://${urlEnv}us1.api.ws-hp.com/permissionsvc/v1`;
  }

  public constructor(baseURL: Stack, authProvider: JarvisAuthProvider) {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultBaseURL: PermissionSetSvcClientExt.getUrl(baseURL),
      defaultAuthProvider: authProvider
    });
  }

  /**
   * Get permission sets information
   *
   * @returns Axios response object
   */
  async getPermissionSets(): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `${this.permissionSetEndpoint}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

class UserTenantMgtSvcClientExt extends UserTenantMgtSvcClient {
  userTenantEndpoint = '/usertenantdetails';
  externalUserEndpoint = '/externaluserdetails';
  userWithTenantEndpoint = '/userwithtenantdetails';

  /**
   * Get currently active user tenant information.
   *
   * @returns Axios response object
   */
  async getCurrentActiveUserTenant(): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `${this.userTenantEndpoint}/me`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  /**
   * Get the user with tenant list for the tenantResourceId.
   *
   * @param {string} tenantResourceId
   * @returns Axios response object
   */
  async getUserWithTenantList(
    tenantResourceId: string
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: this.userWithTenantEndpoint,
      headers: {
        'Content-Type': 'application/json'
      },
      params: { tenantResourceId: tenantResourceId }
    });
  }

  /**
   * Get the external user list for the tenantResourceId.
   *
   * @param {string} tenantResourceId
   * @returns Axios response object
   */
  async getExternalUserList(tenantResourceId: string): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `${this.externalUserEndpoint}/${tenantResourceId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

export default async function getUsersWithRoles(
  stack: Stack,
  authProvider: JarvisAuthProvider
): Promise<T.UserDataType[]> {
  const users = [];
  const userClient = new UserTenantMgtSvcClientExt(stack, authProvider);
  const permClient = new PermissionSetSvcClientExt(stack, authProvider);

  const {
    data: { tenantResourceId }
  } = await userClient.getCurrentActiveUserTenant();

  try {
    const userList = await userClient.getUserWithTenantList(tenantResourceId);
    if (userList.status === 200) {
      userList.data.resourceList
        .filter(
          (item) => item.userTenantDetail.tenantResourceId !== item.user.idpId
        )
        .map((item) => {
          const user: T.UserDataType = {
            userResourceId: item.user.resourceId,
            tenantResourceId: item.userTenantDetail.tenantResourceId,
            rolePermId: item.userTenantDetail.rolePermId,
            roleName: '',
            state: item.userTenantDetail.state
          };
          return user;
        })
        .forEach((item) => users.push(item));
    }
  } catch (err) {
    console.warn('Error while getting user with tenant list', err);
  }

  try {
    const extList = await userClient.getExternalUserList(tenantResourceId);
    if (extList.status === 200) {
      extList.data.resourceList
        .map((item) => {
          const user: T.UserDataType = {
            userResourceId: item.resourceId,
            tenantResourceId: item.tenantResourceId,
            rolePermId: item.rolePermId,
            roleName: '',
            state: item.state
          };
          return user;
        })
        .forEach((item) => users.push(item));
    }
  } catch (err) {
    console.warn('Error while getting external users list', err);
  }

  const permSets = await permClient.getPermissionSets();
  users.forEach((user) => {
    const permSet = permSets.data.content.find(
      (perm) => perm.uuid === user.rolePermId
    );
    if (permSet) {
      user.roleName = permSet.name;
    }
  });

  return users;
}
