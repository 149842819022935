import { Stack } from '@jarvis/web-stratus-client';
import { JarvisAuthProvider } from '@jarvis/web-http';
import * as T from '../../types/statusContentType';
import getUsersWithRoles from '../stratus/getUsersWithRoles';

export default async function getUsersStatusCount(
  stack: Stack,
  authProvider: JarvisAuthProvider,
  stateFilter: string[],
  roleFilter: string[] | Array<string>
): Promise<T.StatusContentType> {
  // Get the v3 users
  const users = await getUsersWithRoles(stack, authProvider);

  // Gets users by role for each role in roleFilter
  const filteredUsers = users.filter(
    (user) => roleFilter.indexOf(user.roleName) > -1
  );

  // Counts active/pending/expired/etc. user depending on the states
  // passed in 'stateFilter'
  const statusList: T.UserStatusItemType[] = stateFilter.map(
    (state): T.UserStatusItemType => ({
      status: T.UserStatusType[state as keyof typeof T.UserStatusType],
      value: filteredUsers.filter((user) => user.state === state).length
    })
  );

  // Sums up the total count of users
  const totalCount = statusList.reduce(
    (acc, counter) => acc + counter.value,
    0
  );

  return {
    type: T.StatusType.User,
    totalCount,
    statusList
  };
}
