import { History } from 'history';

export const handleOnClick = (navigation: History, enableSSOLink?: string) => {
  if (enableSSOLink) {
    return window.open(enableSSOLink);
  } else {
    return navigation.push('/devices');
  }
};
export const constructUrlWithParams = (
  navigation: History,
  connectivity?: string,
  status?: string
) => {
  let url = '/devices?';
  if (connectivity) {
    url += `connectionState=${connectivity}`;
  }
  if (status) {
    if (url !== '/devices?') {
      url += '&';
    }
    url += `status=${status}`;
  }
  navigation.push(url);
  return url;
};
