import React from 'react';
import App from '../src/index';
import packageInfo from '../package.json';
import { MfePropsType } from '../src/types/mfeProps';
import { Settings } from '../src/contexts/Root/types';
import RootProvider from '../src/contexts/Root/RootProvider';
import GenericThemeProvider from '../src/shared/GenericThemeProvider';
import { PreferencesContextProps } from '../src/contexts/Preferences/PreferencesContext';
import ShellV1Type from '../src/types/shell/v1';
import ShellV2Type from '../src/types/shell/v2';

type ShellProps = {
  v1: ShellV1Type;
  v2: ShellV2Type;
};
declare global {
  interface Window {
    Shell: ShellProps;
  }
}
const defaultSettings: Settings = {
  enableLocalization: true
};
type statusProps = {
  properties: PreferencesContextProps;
};
const defaultProps = {
  enableLocalization: true
};

export default function Root({
  properties,
  ...props
}: statusProps & MfePropsType) {
  const { v1, v2 } = window.Shell as ShellProps;

  const shell = {
    ...v1,
    ...v2
  };
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();

  const mfeProps = {
    ...props,
    ...v1,
    ...v2,
    settings: {
      ...defaultSettings,
      ...props.settings
    },
    id: packageInfo.name
  };

  return (
    <GenericThemeProvider {...themeProviderProps}>
      <RootProvider
        {...defaultProps}
        {...props}
        {...mfeProps}
        shell={shell}
        preferences={properties}
      >
        <App
          {...props}
          {...properties}
        />
      </RootProvider>
    </GenericThemeProvider>
  );
}
