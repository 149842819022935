import React from 'react';
import { LocalizationType } from '../types/shell/v1';

const TranslatorProvider = ({ children }) => <div>{children}</div>;

export const shouldMockLocalization = (mock, shellLocalization) => {
  if (mock) {
    return true;
  }

  if (!shellLocalization || !shellLocalization.enabled) {
    return true;
  }

  return false;
};

export const getMockedLocalization = (
  resources,
  localization: LocalizationType
): LocalizationType => ({
  ...localization,
  useReactTranslatorHook: () => ({
    t: (id = '', options = {}) => {
      let str = (id ? id.split('.') : []).reduce((prev, cur) => {
        return prev ? prev[cur] : null;
      }, resources.en_US);

      Object.entries(options).forEach(([key, value]) => {
        str = str.replace(`{{${key}}}`, value);
      });

      return str;
    }
  }),
  getReactTranslatorProvider: () => TranslatorProvider
});
