import * as T from '../../types/statusContentType';

export const onlineDevicesList: T.DeviceStatusItemType = {
  status: T.DeviceStatusType.Online,
  value: 0
};

export const offlineDevicesList: T.DeviceStatusItemType = {
  status: T.DeviceStatusType.Offline,
  value: 0
};

export const devicesList: T.StatusContentType = {
  type: T.StatusType.Device,
  totalCount: 0,
  statusList: [onlineDevicesList, offlineDevicesList]
};
