import React from 'react';
import { Button, IconWarningAlt } from '@veneer/core';
import * as T from './types';
import * as S from './styles';
import testid from '../../data-testid';
import { useRootContext } from '../../contexts/Root';

export const ErrorComponent = ({ onClick, ...props }: T.ErrorComponentType) => {
  const { localization } = useRootContext();
  const { t } = localization.useReactTranslatorHook();

  return (
    <S.Container
      data-testid={testid('error-component')}
      {...props}
    >
      <S.IconDiv>
        <IconWarningAlt size={24} />
      </S.IconDiv>
      <p className="label">{t('errorWidget.defaultMessage')}</p>
      <Button
        appearance="ghost"
        small
        {...{ onClick }}
      >
        {t('errorWidget.retry')}
      </Button>
    </S.Container>
  );
};
export default ErrorComponent;
