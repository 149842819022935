import PropTypes from 'prop-types';
const activity = 'Home-v01';
const screenPath = '/ReactEcpStatusWidget/';
const eventDetailVersion = '1.1.0';

export const publishEvent = (event) => {
  const publishCdmEvents = window?.Shell?.v1?.analytics?.publishCdmEvents;

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined');
    return;
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType:
        'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0'
    }
  ]);
};

export const AnalyticsEventPropType = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
};

// Events

export const StatusTotalPrintersClicked = (count) => {
  return {
    action: 'ControlButtonClicked',
    activity,
    screenPath,
    screenName: 'Status',
    controlName: 'StatusTotalPrintersButton',
    controlDetail: `Total Printer Count : ${count}`,
    version: eventDetailVersion
  };
};

export const StatusTotalUsersClicked = (count) => {
  return {
    action: 'ControlButtonClicked',
    activity,
    screenPath,
    screenName: 'Status',
    controlName: 'StatusTotalUsersButton',
    controlDetail: `Total Users Count : ${count}`,
    version: eventDetailVersion
  };
};

// Events helpers

export const getModuleInitializedEvent = (component) => ({
  action: 'ModuleInitialized',
  activity,
  screenPath,
  screenName: 'Home',
  controlName: component,
  version: eventDetailVersion
});

export const getModuleDisplayedEvent = (component, screenName) => ({
  action: 'ModuleDisplayed',
  activity,
  screenPath: `${component}`,
  screenName,
  version: eventDetailVersion
});
