import Axios, { AxiosInstance, AxiosResponse } from 'axios'
import AxiosRetry from 'axios-retry'
import Ajv from "ajv"

import { JarvisAuthProvider } from './auth-provider/jarvis-auth-provider'
import { JarvisWebHttpClientRequestOptions } from './jarvis-web-http-client-request-options'

export interface JarvisWebHttpClientConfig {
  /**
   * Optional default base URL for the axios instance
   * Can be overriden on each request
   */
  defaultBaseURL?: (() => Promise<string>) | string,

  /**
   * Optional default Jarvis auth provider used to get a Bearer access token
   * Can be overriden on each request
   */
  defaultAuthProvider?: JarvisAuthProvider,

  /**
   * Optional default for number of retries on HTTP 5XX response status
   * Can be overriden on each request
   * Defaults to 3
   */
  defaultRetries?: number
}

/**
 * Custom error that occurs when API data does not match the expected schema.
 */
export class SchemaValidationError extends Error {
  constructor (message: string) {
      super (message)
      this.constructor = SchemaValidationError
      Object.setPrototypeOf(this, SchemaValidationError.prototype)
      this.name = SchemaValidationError.name
      this.message = message
  }
}

// Value between 
// - 30s  https://docs.oracle.com/cd/E19900-01/819-4742/abefk/index.html
// - 100s https://docs.microsoft.com/en-us/dotnet/api/system.net.http.httpclient.timeout?view=net-5.0
const DEFAULT_TIMEOUT = 40_000

/**
 * JarvisWebHttp is the Jarvis Wrapper Around Axios
 * Provides for error handling and retries.
 */
export class JarvisWebHttpClient {
  private axiosInstance: AxiosInstance
  private defaultBaseURL: (() => Promise<string>) | string | undefined
  private defaultAuthProvider: JarvisAuthProvider | undefined
  private defaultRetries = 3

  /**
   * Initializes a new instance of the JarvisWebHttp class
   *
   * @param config client configuration
   */
  constructor(config?: JarvisWebHttpClientConfig) {
    this.axiosInstance = Axios.create()
    if (config) {
      this.defaultBaseURL = config.defaultBaseURL
      this.defaultAuthProvider = config.defaultAuthProvider
      if (config.defaultRetries) {
        this.defaultRetries = config.defaultRetries
      }
    }
    AxiosRetry(this.axiosInstance, {
      retries: this.defaultRetries,
      retryDelay: AxiosRetry.exponentialDelay
    })
  }

  /**
   * request
   *
   * @param options That specifies AuthProvider, Retry Amount, and Axios Request Configuration
   */
  async request(
    options: JarvisWebHttpClientRequestOptions
  ): Promise<AxiosResponse<any>> {
    let axiosRequestConfig = options

    let baseURL = options.baseURL ? options.baseURL : this.defaultBaseURL
    if (baseURL) {
      axiosRequestConfig.baseURL = typeof baseURL === 'function' ? await baseURL() : baseURL
    }

    let authProvider = this.defaultAuthProvider
    if (options.authProvider) {
      authProvider = options.authProvider
    }

    let retries = this.defaultRetries
    if (options.retries || options.retries === 0) {
      retries = options.retries
    }

    axiosRequestConfig['axios-retry'] = {
      retries: retries
    }

    // set default timeout to requests
    if (options.timeout == null) {
      axiosRequestConfig.timeout = DEFAULT_TIMEOUT
    }

    let authHeaderSetByUs = false
    if (authProvider) {
      axiosRequestConfig.headers = axiosRequestConfig.headers || {}

      // Don't set the Authorization header if it's already set in the request options
      if (typeof axiosRequestConfig.headers['Authorization'] === 'undefined') {
        authHeaderSetByUs = true
        let accessToken = await authProvider.getAccessToken()
        axiosRequestConfig.headers['Authorization'] = 'Bearer ' + accessToken
      }
    }

    try {
      return await this.makeRequest(axiosRequestConfig)
    }
    catch(error: any) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (authHeaderSetByUs && authProvider && error.response && error.response.status === 401) {
        // Unauthorized. Try to refresh a token with the auth provider
        let accessToken = await authProvider.getAccessToken(true)
        if (axiosRequestConfig.headers) {
          axiosRequestConfig.headers['Authorization'] = 'Bearer ' + accessToken
        }

        return this.makeRequest(axiosRequestConfig)
      }
      throw error
    }
  }

  private async makeRequest(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> {
    let response = await this.axiosInstance.request(options)
    if ([200, 201, 202, 203].indexOf(response.status) !== -1 &&
        options.validationSchema
        && !new Ajv().validate(options.validationSchema, response.data)) {
      let requestMethod = "GET" || options.method
      let message = requestMethod.toUpperCase() + " request for " + options.url + " response does not match validationSchema"
      throw new SchemaValidationError(message);
    }
    return response
  }

  get(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'get'})) }
  put(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'put'})) }
  head(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'head'})) }
  post(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'post'})) }
  patch(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'patch'})) }
  options(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'options'})) }
  delete(options: JarvisWebHttpClientRequestOptions): Promise<AxiosResponse<any>> { return this.request(Object.assign(options, {method: 'delete'})) }
}
