import { createContext } from 'react';

export type ManageStatusWidgetType = {
  hideUsersCount?: boolean;
};

export type PreferencesContextProps = {
  manageStatusWidget?: ManageStatusWidgetType;
};

export const defaultPreferences: PreferencesContextProps = {
  manageStatusWidget: {
    hideUsersCount: false
  }
};

const PreferencesContext = createContext({} as PreferencesContextProps);

export default PreferencesContext;
